/*eslint-disable*/
import { MatomoSettings } from './shared/MatomoSettings';

interface IConfig {
    azureMapKey: string;
    powerBiUrl: string,
    minimumLoadCapacity: number,
    UseSPAAndApiSeparately: boolean,
    cartoUrl: string,
    cartoToken: string,
    vectuelMapUrl: string,
    mapprUrl: string,
    vectuelProjectName: string,
    chantier360Url: string,
    carbonCounterUrl: string,
    mapBoxToken: string,
    dataProxyApiUrl: string,
    inexApiUrl: string,
    configMapBoxLayersUrl: string,
    matomo: MatomoSettings,
    mapillaryToken: string,
    auth: Auth,
    maxPOIsToCreate: number
}

interface Auth {
    clientId : string,
    connexionPolicy : string,
    tenantId : string
}

export class ConfigService {
    private static _config: IConfig = null;

    private constructor() {

    }

    static get = () => {
        if (ConfigService._config)
            return ConfigService._config;
        throw console.error('get conf erreur: vous devez réinitialiser votre configuration');
    }

    public static setup = (res) => {
        if (res) {
            var config = JSON.parse(res);
            ConfigService._config = config;
        }
    }
}