import { Configuration, LogLevel } from '@azure/msal-browser';
import { ConfigService } from './configService';

 
export const getMsalConfig  = () : Configuration  => {
    return {
        auth: {
          clientId:  ConfigService.get().auth.clientId,
          authority: `https://${ConfigService.get().auth.tenantId}.b2clogin.com/${ConfigService.get().auth.tenantId}.onmicrosoft.com/${ConfigService.get().auth.connexionPolicy}`,
          redirectUri: "/",
          knownAuthorities: [`${ConfigService.get().auth.tenantId}.b2clogin.com`],
          // navigateToLoginRequestUrl: true,
        },
        cache: {
          //Location of token cache in browser. Default: "sessionStorage"
          cacheLocation: "sessionStorage", // This configures where your cache will be stored
      
          //If true, stores cache items in cookies as well as browser cache. Should be set to true for use cases using IE. Default: false
          // Set this to "true" if you are having issues on IE11 or Edge
          //storeAuthStateInCookie: true,
      
          //If true and storeAuthStateInCookies is also enabled, MSAL adds the Secure flag to the browser cookie so it can only be sent over HTTPS. Default: false
          secureCookies: false
          },
          system: {	
              loggerOptions: {	
                  loggerCallback: (level, message, containsPii) => {	
                      if (containsPii) {		
                          return;		
                      }		
                      switch (level) {
                          case LogLevel.Error:
                              console.error(message);
                              return;
                          case LogLevel.Info:
                              console.info(message);
                              return;
                          case LogLevel.Verbose:
                              console.debug(message);
                              return;
                          case LogLevel.Warning:
                              console.warn(message);
                              return;
                          default:
                              return;
                      }	
                  }	
              }	
          }
      };
}

export const getLoginRequest = () =>{
    return {
        scopes: ["openid"],
        acquireTokenScopes: [`https://${ConfigService.get().auth.tenantId}.onmicrosoft.com/default/user_impersonation`]
    };
}
