import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import i18n from './i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { ConfigService } from './configService';
import buildinfo from './config/buildinfo.json';
// const App = React.lazy(() => import('./App'));
const AppBasic = React.lazy(() => import('./AppBasic'));
import {  getLoginRequest, getMsalConfig } from "./MsalConfig";
import { Configuration, EventMessage, EventType, IPublicClientApplication, InteractionRequiredAuthError, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import axios, { AxiosRequestConfig } from 'axios';
import { toast } from "react-toastify";

window.document.title += ` v3.${buildinfo.versionMajorMinor}.${buildinfo.build.buildNumber}`;

let msalInstance : PublicClientApplication;

function renderApp(){
  const baseUrl = "/";
  const rootElement = document.getElementById('root');

    ReactDOM.render(
      <BrowserRouter basename={baseUrl}>
        <React.Suspense fallback={<div></div>}>
        <MsalProvider instance={msalInstance}>
          <AppBasic />
        </MsalProvider>
        </React.Suspense>
      </BrowserRouter>,
      rootElement);
    registerServiceWorker();
}

var settingsPromise = new Promise((resolve, reject)=> {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", 'config/appsettings.json');
    xhr.onload = () => resolve(xhr.responseText);
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  });
  settingsPromise.then((res)=>{
    ConfigService.setup(res);
    msalInstance = new PublicClientApplication(getMsalConfig() );
    configMsalInstance(msalInstance);
    renderApp();
  });

const configMsalInstance = (msalInstance : PublicClientApplication) => {

    msalInstance.addEventCallback((event: EventMessage) => {

        let accounts; 
        switch (event.eventType) {
    
            case EventType.HANDLE_REDIRECT_END:
    
                accounts = msalInstance.getAllAccounts()
                if (accounts.length > 0) {
                    msalInstance.setActiveAccount(accounts[0]);
    
                    //setup interceptors
                    setupAxiosInterceptors(msalInstance, displayGenericError);
                }
                break;
            default:
                break;
        }
    });
  }

const displayGenericError = (err) => {
    console.error(err);
    // Pas de notif à l'utilisateur de manière générique globale
}

const setupAxiosInterceptors = (msalInstance: IPublicClientApplication, genericErrorHandler: (error: any) => void) => {

  axios.interceptors.request.use(

      //onFulfilled
      (config: AxiosRequestConfig) => {

        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        return new Promise((resolve, reject) => {

            //See: Token Lifetimes, Expiration, and Renewal
            //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#token-renewal

            const silentRequest: SilentRequest = { scopes: getLoginRequest().acquireTokenScopes, account: account };
            return msalInstance.acquireTokenSilent(silentRequest)

                .then((responseOfAcquireTokenSilent) => {
                    if (responseOfAcquireTokenSilent?.accessToken) {
                        config.headers.Authorization = `Bearer ${responseOfAcquireTokenSilent.accessToken}`;
                        config.headers.lang = i18n.language;
                        resolve(config);
                    } else {
                        // Do something with error of acquiring the token
                        console.error(responseOfAcquireTokenSilent);
                        reject(config);
                    }
                })

                .catch((errorOfAcquireTokenSilent) => {
                    // fallback to interaction when silent call fails
                    if (errorOfAcquireTokenSilent instanceof InteractionRequiredAuthError) {
                        console.warn(errorOfAcquireTokenSilent);
                        return msalInstance.acquireTokenRedirect(silentRequest);
                    } else {
                        console.error(errorOfAcquireTokenSilent);
                        return Promise.reject(config);
                    }
                });
        })
    },

      //onRejected
      (error: any) => {
          // Do something with error of the request
          return Promise.reject(error)
      });

  //En cas de session expirée, l'utilisateur est redirigé vers la page d'authentification
  axios.interceptors.response.use(

      undefined,

      err => {

          if (err && err.response && err.response.status === 401) {
              msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
          }

          if (!err.config?.__colas_xroad_disableGenericErrorHandler) {
              genericErrorHandler(err);
          }

          return Promise.reject(err);
      });
}





